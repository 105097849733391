import React from "react";

// Components

import Navbar from "components/Navbars/AuthNavbar.js";
import Footer from "components/Footers/Footer.js";

// IMG
import bgHero from "assets/img/hero-bg.jpg"

export default function Landing() {
  return (
    <>
      <Navbar transparent />
      <main>
        <div className="relative pt-16 pb-32 flex content-center items-center justify-center min-h-screen-75">
          <div
            className="absolute top-0 w-full h-full bg-center bg-cover"
            style={{
              backgroundImage: `url(${bgHero})`,
            }}
          >
            <span
              id="blackOverlay"
              className="w-full h-full absolute opacity-75 bg-black"
            ></span>
          </div>
          <div className="container relative mx-auto">
            <div className="items-center flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
                <div className="pr-12">
                  <h1 className="text-white font-semibold text-5xl">
                    QuiHydro
                  </h1>
                  <p className="mt-4 text-lg text-blueGray-200">
                    Soluciones ecológicas, sustentables y efectivas. ¡Invierta en el futuro de la agricultura y vea cómo sus cultivos prosperan!
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
            style={{ transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-blueGray-200 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
        </div>

        <section className="pb-20 bg-blueGray-200 -mt-24">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap">
              <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-400">
                      <i className="fas fa-award"></i>
                    </div>
                    <h6 className="text-xl font-semibold">Nuestra Misión</h6>
                    <p className="mt-2 mb-4 text-blueGray-500">
                      Impulsar la agricultura hidropónica y de campo con hidrogeles fertilizantes para proporcionar cultivos sostenibles y de alta calidad.
                    </p>
                  </div>
                </div>
              </div>

              <div className="w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-lightBlue-400">
                      <i className="fas fa-retweet"></i>
                    </div>
                    <h6 className="text-xl font-semibold">Nuestro Plan</h6>
                    <p className="mt-2 mb-4 text-blueGray-500">
                      Diseñar un sistema automatizado de germinacion de hortalizas para el tomate cherry y lechuga que ayude a optimizar tiempos de producción y recursos hidricos.
                    </p>
                  </div>
                </div>
              </div>

              <div className="pt-6 w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-emerald-400">
                      <i className="fas fa-fingerprint"></i>
                    </div>
                    <h6 className="text-xl font-semibold">Nuestra Visión</h6>
                    <p className="mt-2 mb-4 text-blueGray-500">
                      Aspiramos a liderar la revolución agrícola al hacer que la germinacion por hidrogeles fertilizantes contribuya a unos cultivos más productivos y saludables.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-wrap items-center mt-32">
              <div className="w-full md:w-5/12 px-4 mr-auto ml-auto">
                <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white">
                  <i className="fas fa-user-friends text-xl"></i>
                </div>
                <h3 className="text-3xl mb-2 font-semibold leading-normal">
                  Tu Éxito es Nuestra Prioridad
                </h3>
                <p className="text-lg font-light leading-relaxed mt-0 mb-4 text-blueGray-600">
                  No dejes nada al azar cuando se trata de optimizar tu producción agrícola. Nuestra solución ha sido diseñada para hacer que trabajar con nosotros sea una experiencia gratificante. Descubre cómo puedes aumentar la eficiencia, mejorar la calidad de tus productos y expandir tus operaciones sin complicaciones innecesarias. Estamos aquí para hacer que tu camino hacia el éxito en la agroindustria sea lo más placentero posible.
                </p>
              </div>

              <div className="w-full md:w-4/12 px-4 mr-auto ml-auto">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-lightBlue-500">
                  <img
                    alt="..."
                    src={require("assets/img/bg-2.png")}
                    className="w-full align-middle rounded-t-lg"
                  />
                  <blockquote className="relative p-8 mb-4">
                    <svg
                      preserveAspectRatio="none"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 583 95"
                      className="absolute left-0 w-full block h-95-px -top-94-px"
                    >
                      <polygon
                        points="-30,95 583,95 583,65"
                        className="text-lightBlue-500 fill-current"
                      ></polygon>
                    </svg>
                    <h4 className="text-xl font-bold text-gray">
                      Potencia tu Cosecha con Hidrogel
                    </h4>
                    <p className="text-md font-light mt-2 text-gray">
                      Descubre el Poder del Hidrogel en la Germinación
                      El hidrogel es tu mejor amigo para hacer crecer tus cultivos. Imagina que tus plantas tienen su propia fuente de agua, siempre disponible cuando la necesitan.
                      ¿Listo para cambiar la forma en que germinas tu plantas? Únete al uso del hidrogel y haz que tus cultivos sean más fuertes y verdes.
                    </p>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="relative py-20">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
            style={{ transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-white fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>

          <div className="container mx-auto px-4">
            <div className="items-center flex flex-wrap">
              <div className="w-full md:w-4/12 ml-auto mr-auto px-4">
                <img
                  alt="..."
                  className="max-w-full rounded-lg"
                  src={require("assets/img/bg-1.png")}
                />
              </div>
              <div className="w-full md:w-5/12 ml-auto mr-auto px-4">
                <div className="md:pr-12">
                  <div className="text-lightBlue-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-lightBlue-300">
                    <i className="fas fa-rocket text-xl"></i>
                  </div>
                  <h3 className="text-3xl font-semibold">Medio Hidropónico</h3>
                  <p className="mt-4 text-lg leading-relaxed text-blueGray-500">
                    La combinación de hidrogel con fertilizantes mejorar significativamente el crecimiento de la planta. El hidrogel actúa como medio retenedor de agua, asegurando un suministro constante de humedad a las raíces.
                  </p>
                  <ul className="list-none mt-6">
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                            <i className="fas fa-check-double"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                            Plántulas de tomate cherry y lechuga con mejor desarrollo radicular.
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                            <i className="fas fa-check-double"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                            Plántulas libre de patógenos, con alto rendimiento de germinación.
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                            <i className="fas fa-check-double"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                            Reducción en el consumo de agua en un 90% de sistemas hidropónicos.
                          </h4>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pt-20 pb-48">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap justify-center text-center mb-24">
              <div className="w-full lg:w-6/12 px-4">
                <h2 className="text-4xl font-semibold">Staff Profesional</h2>
                <p className="text-lg leading-relaxed m-4 text-blueGray-500">
                  Somos un equipo de profesionales apasionados y altamente capacitados que se dedican a ofrecer soluciones de calidad con experiencia y compromiso.
                </p>
              </div>
            </div>
            <div className="flex flex-wrap justify-center text-center">
              <div className="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 p-4">
                <div className="px-6">
                  <img
                    alt="..."
                    src={require("assets/img/Thais_bio.png")}
                    className="shadow-lg rounded-full mx-auto max-w-120-px"
                  />
                  <div className="pt-6 text-center">
                    <h5 className="text-xl font-bold">Tahis Montalvo</h5>
                    <p className="mt-1 text-sm text-blueGray-400 uppercase font-semibold">
                      Ing. Biotecnóloga
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 p-4">
                <div className="px-6">
                  <img
                    alt="..."
                    src={require("assets/img/Dannyel_itin.jpg")}
                    className="shadow-lg rounded-full mx-auto max-w-120-px"
                  />
                  <div className="pt-6 text-center">
                    <h5 className="text-xl font-bold">Dannyel Alulema</h5>
                    <p className="mt-1 text-sm text-blueGray-400 uppercase font-semibold">
                      Ing. Tecnologías de la Información
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 p-4">
                <div className="px-6">
                  <img
                    alt="..."
                    src={require("assets/img/Sharon_Mct.jpg")}
                    className="shadow-lg rounded-full mx-auto max-w-120-px"
                  />
                  <div className="pt-6 text-center">
                    <h5 className="text-xl font-bold">Sharon Vela</h5>
                    <p className="mt-1 text-sm text-blueGray-400 uppercase font-semibold">
                      Ing. Mecatrónica
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 p-4">
                <div className="px-6">
                  <img
                    alt="..."
                    src={require("assets/img/Daniel_tele.jpg")}
                    className="shadow-lg rounded-full mx-auto max-w-120-px"
                  />
                  <div className="pt-6 text-center">
                    <h5 className="text-xl font-bold">Daniel Oleas</h5>
                    <p className="mt-1 text-sm text-blueGray-400 uppercase font-semibold">
                      Ing. Telecomunicaciones
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 p-4">
                <div className="px-6">
                  <img
                    alt="..."
                    src={require("assets/img/Andres_mct.jpg")}
                    className="shadow-lg rounded-full mx-auto max-w-120-px"
                  />
                  <div className="pt-6 text-center">
                    <h5 className="text-xl font-bold">Andres Villamarin</h5>
                    <p className="mt-1 text-sm text-blueGray-400 uppercase font-semibold">
                      Ing. Mecatrónico
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 p-4">
                <div className="px-6">
                  <img
                    alt="..."
                    src={require("assets/img/bryan_mct.jpg")}
                    className="shadow-lg rounded-full mx-auto max-w-120-px"
                  />
                  <div className="pt-6 text-center">
                    <h5 className="text-xl font-bold">Bryan Ipial</h5>
                    <p className="mt-1 text-sm text-blueGray-400 uppercase font-semibold">
                      Ing. Mecatrónico
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pb-20 relative block bg-blueGray-800">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
            style={{ transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-blueGray-800 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>

          <div className="container mx-auto px-4 lg:pt-24 lg:pb-64">
            <div className="flex flex-wrap text-center justify-center">
              <div className="w-full lg:w-6/12 px-4">
                <h2 className="text-4xl font-semibold text-white">
                  Mejora tu productividad
                </h2>
                <p className="text-lg leading-relaxed mt-4 mb-4 text-blueGray-400">
                  Nuestra tecnología te ayuda a reducir el uso de recursos y mejorar tus ingresos.
                </p>
              </div>
            </div>
            <div className="flex flex-wrap mt-12 justify-center">
              <div className="w-full lg:w-3/12 px-4 text-center">
                <div className="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                  <i className="fas fa-medal text-xl"></i>
                </div>
                <h6 className="text-xl mt-5 font-semibold text-white">
                  Optimización Agrícola
                </h6>
                <p className="mt-2 mb-4 text-blueGray-400">
                Potencia tu cosecha con nuestra solución avanzada. Automatiza procesos y mejora la eficiencia. Descubre el futuro de la agricultura con nosotros.
                </p>
              </div>
              <div className="w-full lg:w-3/12 px-4 text-center">
                <div className="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                  <i className="fas fa-poll text-xl"></i>
                </div>
                <h5 className="text-xl mt-5 font-semibold text-white">
                  Expande tu Alcance
                </h5>
                <p className="mt-2 mb-4 text-blueGray-400">
                Transforma tu producción agrícola con nuestra solución de automatización. Optimiza cada paso, desde la siembra hasta la cosecha, para un éxito agrícola sin precedentes.
                </p>
              </div>
              <div className="w-full lg:w-3/12 px-4 text-center">
                <div className="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                  <i className="fas fa-lightbulb text-xl"></i>
                </div>
                <h5 className="text-xl mt-5 font-semibold text-white">
                  ¡Es Hora de Crecer!
                </h5>
                <p className="mt-2 mb-4 text-blueGray-400">
                  Con nuestro sistema de optimización agrícola, es el momento de impulsar un crecimiento exponencial. Automatiza tus procesos agrícolas para alcanzar un nuevo nivel de eficiencia y prosperidad en tu producción.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="relative block py-24 lg:pt-0 bg-blueGray-800">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap justify-center lg:-mt-64 -mt-48">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200">
                  <div className="flex-auto p-5 lg:p-10">
                    <h4 className="text-2xl font-semibold">
                      ¿Quieres trabajar con nosotros?
                    </h4>
                    <p className="leading-relaxed mt-1 mb-4 text-blueGray-500">
                      Completa el siguiente formulario y nos pondremos en contacto lo mas pronto posible.
                    </p>
                    <div className="relative w-full mb-3 mt-8">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="full-name"
                      >
                        Nombre / Empresa
                      </label>
                      <input
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Ingresa tu nombre o la razón social de tu empresa"
                      />
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="email"
                      >
                        Email
                      </label>
                      <input
                        type="email"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Ingresa el correo electrónico de contacto"
                      />
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="message"
                      >
                        Mensaje
                      </label>
                      <textarea
                        rows="4"
                        cols="80"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                        placeholder="Escribe tu mensaje..."
                      />
                    </div>
                    <div className="text-center mt-6">
                      <button
                        className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                      >
                        Enviar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}